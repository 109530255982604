import axios from "axios";
import {
  CONTRACT_REQUEST,
  CONTRACT_SUCCESS,
  CONTRACT_FAIL,
} from "../constants/contractConstants";

export const getContract = (code) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACT_REQUEST });

    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}api/Contract/getContract/${code}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      },
    };
    const { data } = await axios(config);

    dispatch({
      type: CONTRACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACT_FAIL,
      payload:error.response.data,
    });
  }
};


export const getContractSale = (code) => async (dispatch) => {
  try {
    dispatch({ type: CONTRACT_REQUEST });

    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}api/Contract/getContractSale/${code}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      },
    };
    const { data } = await axios(config);

    dispatch({
      type: CONTRACT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CONTRACT_FAIL,
      payload:error.response.data,
    });
  }
};
