import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageNetFound from "./PageNotFound";
import { getUsage } from "../actions/usageActions";
import "./UsageDetail.css"

const UsageDetail = ({ history, match }) => {
  const code = match.params.id;
  const dispatch = useDispatch();
  const usageReducer = useSelector((state) => state.usageReducer);
  const { loading, error, usage } = usageReducer;
  
  
  useEffect(() => {
    dispatch(getUsage(code));
  }, [dispatch, code]);

  var branch = "";
  var contract = "";
  var doc_no = "";

  return loading ? (
    <div className="text-center">
      <div className="spinner-border" role="status"></div>
      <br></br>
      {"กำลังโหลดข้อมูล..."}
    </div>
  ) : error ? (
    <>
      <PageNetFound message={error} />
    </>
    
  ) : (
    <div >
        <div>
          <h5>วันที่ให้บริการ {usage[0].USAGE_DATE}</h5>
          <br/>
        </div>              
        {usage.map((x, index) => 
          <div className="Wrapper" key={index}>
            <div className="headline">             
              {x.BRANCH_NAME !== branch && <h6> สาขา {branch = x.BRANCH_NAME}</h6>}              
            </div>           
            <div className="headline">
              {x.CONTRACT_NO !== contract && <div style={{width : "100%" , textAlign: "left"}}>{index !== 0 && <hr/>}<h6> Contract No. {contract = x.CONTRACT_NO}</h6></div>}             
            </div>  
            <div className="headline">
              {x.DOC_NO !== doc_no && <h6> Ref.# {doc_no = x.DOC_NO}</h6>}
            </div>              
{/*             
            {x.DOCTOR_NAME || x.NURSE_NAME ?
            <div className="listName">
              {x.DOCTOR_NAME && <div style={{textAlign: "left"}}><h6>แพทย์ <span>{x.DOCTOR_NAME}</span></h6></div>}
              {x.NURSE_NAME && <div style={{textAlign: "right"}}><h6>พยาบาล <span>{x.NURSE_NAME}</span></h6></div>}
            </div> : ""
            }

            {x.PHYSICAL_NAME || x.NUTRITIONIST_NAME ?
            <div className="listName">
              {x.PHYSICAL_NAME && <div style={{textAlign: "left"}}><h6>นักกายภาพ <span>{x.PHYSICAL_NAME}</span></h6></div>}
              {x.NUTRITIONIST_NAME && <div style={{textAlign: "right"}}><h6>นักโภชนาการ <span>{x.NUTRITIONIST_NAME}</span></h6></div>}                          
            </div> : ""
            }  */}
            <div style={{textAlign: "left"}}><h6>Service :</h6></div>
            <div className="listName">
              {x.TM_DETALIS && <div style={{textAlign: "left"}}><h6>{x.TM_DETALIS}</h6></div>}
              {x.QTY && <div style={{textAlign: "right"}}><h6>{x.QTY}</h6></div>}                          
            </div>

            {x.THERAPIST_NAME || x.REQUESTOR_NAME ? 
              <div className="listName">
                {x.THERAPIST_NAME && <div style={{textAlign: "left"}}><h6>พนักงานผู้ให้บริการ <span>{x.THERAPIST_NAME}</span></h6></div>}
                {/* {x.REQUESTOR_NAME && <div style={{textAlign: "right"}}><h6>ผู้มอบหมาย <span>{x.REQUESTOR_NAME}</span></h6></div>}                           */}
              </div> : ""
            }

            {/* {x.BOM_DETAILS && <div style={{textAlign: "left"}}>{x.BOM_DETAILS.map(x => <p>{x}</p>)}</div>}     */}
          </div>    
            
        )}
        <hr/> 
        <h5>คุณ {usage[0].CUS_NAME}</h5>
        <h5>ขอบคุณที่ใช้บริการ</h5>
        <br/><br/>
    </div>
  );

};

export default UsageDetail;
