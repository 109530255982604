import axios from "axios";
import {
  USAGE_REQUEST,
  USAGE_SUCCESS,
  USAGE_FAIL,
} from "../constants/usageConstants";

export const getUsage = (code) => async (dispatch) => {
  try {
    dispatch({ type: USAGE_REQUEST });
    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}Usage/${code}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      },
    };
    const { data } = await axios(config);
    dispatch({
      type: USAGE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: USAGE_FAIL,
      payload:
        error.response.data
    });
  }
};
