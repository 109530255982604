import {
  ADDPAYMENT_REQUEST,
  ADDPAYMENT_SUCCESS,
  ADDPAYMENT_FAIL,
} from "../constants/addpaymentConstants";

export const addpaymentReducer = (
    state = { addpayment: {}, loading: true, error: "" },
    action
  ) => {
    switch (action.type) {
      case ADDPAYMENT_REQUEST:
        return { loading: true, addpayment: {} };
      case ADDPAYMENT_SUCCESS:
        return { loading: false, addpayment: action.payload.addpayment };
      case ADDPAYMENT_FAIL:
        return { loading: false, addpayment: {}, error: action.payload };
      default:
        return state;
  }
};

