import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Container from "react-bootstrap/Container";
import ContractDetail from "./screens/ContractDetail";
import ContractDetailV2 from "./screens/ContractDetailV2";
import UsageDetail from "./screens/UsageDetail";
import AddpaymentDetail from "./screens/AddpaymentDetail";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Navber from "./components/Navber";

const App = () => {
  return (
    <Router>
      <Container>
        <Navber/>
        <Route path="/contract/:id" component={ContractDetail} />
        <Route path="/sale/:id" component={ContractDetailV2} />
        <Route path="/usage/:id" component={UsageDetail} />
        <Route path="/addpayment/:id" component={AddpaymentDetail} />
      </Container>
    </Router>
  );
};

export default App;
