import {
  USAGE_REQUEST,
  USAGE_SUCCESS,
  USAGE_FAIL,
} from "../constants/usageConstants";

export const usageReducer = (
  state = { usage:{}, loading: true, error: "" },
  action
) => {
  switch (action.type) {
    case USAGE_REQUEST:
      return { loading: true, usage: {} };
    case USAGE_SUCCESS:
      return { loading: false, usage: action.payload.usages };
    case USAGE_FAIL:
      return { loading: false, usage: {}, error: action.payload };
    default:
      return state;
  }
};
