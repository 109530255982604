import React from "react";

const PageNotFound = ({ message }) => {
  return (
    <div className="text-center">
      ไม่พบข้อมูล <br></br> <code>{message}</code>
    </div>
  );
};

export default PageNotFound;
