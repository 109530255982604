import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageNetFound from "./PageNotFound";
import { getContractSale } from "../actions/contractActions";

const ContractDetailV2 = ({ history, match }) => {
  const code = match.params.id;
  const dispatch = useDispatch();
  const contractReducer = useSelector((state) => state.contractReducer);
  const { loading, error, contract } = contractReducer;

  useEffect(() => {
    dispatch(getContractSale(code));
  }, [dispatch, code]);

  //console.log(contract);

  return loading ? (
    <div className="text-center">
      <div className="spinner-border" role="status"></div>
      <br></br>
      {"กำลังโหลดข้อมูล..."}
    </div>
  ) : error ? (
    <>
      <PageNetFound message={error} />
    </>
  ) : (
    <>
      <h2>{contract[0].DATA_AREA_NAME}</h2>
      <div className="d-flex justify-content-between">
        <h5>คุณ {contract[0].CUSTOMER_NAME_TH}</h5>
        <h5>วันที่ซื้อ {contract[0].SELLING_DATE}</h5>
      </div>
      {contract.map((ct) => {
        //console.log(ct);
        return (
          <div>
            <div className="d-flex justify-content-between">
              <h6>สาขาที่ซื้อ {ct.BRANCH_NAME}</h6>
            </div>
            {ct.POINT > 0 ? (
              <div className="d-flex justify-content-between">
                <span>Point ที่ได้รับจากการซื้อครั้งนี้</span>
                <span>{ct.POINT}</span>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex justify-content-between">
              <h6>รายละเอียดสินค้า</h6>
            </div>
            {ct.CONTRACT_DETAILS.map((x, index) => {
              return (
                <li
                  style={{ listStyleType: "none", paddingLeft: "10px" }}
                  key={index}
                  className="d-flex justify-content-between"
                >
                  <span>
                    {index + 1}.{x.name}
                  </span>
                  <span>{x.qty}</span>
                </li>
              );
            })}
            <div className="d-flex justify-content-between">
              <span>รวม</span>
              <span>{ct.CONTRACT_SALE_PRICE}.-</span>
            </div>
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default ContractDetailV2;
