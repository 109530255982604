import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import './Navbar.css'
const Navber = () => {
  
  const brand = process.env.REACT_APP_BRAND.toLocaleLowerCase();

  return (
    <Container className="centerimg">
      <Navbar.Brand href="/" >
        <img
          src={require(`../logo/${brand}_logo.png`)}
          style={{ width: "200px", height: "50px"}}
          alt="Logo"
        />
      </Navbar.Brand>
    
    </Container>
  );
};
export default Navber;
