import axios from "axios";
import {
  ADDPAYMENT_REQUEST,
  ADDPAYMENT_SUCCESS,
  ADDPAYMENT_FAIL,
} from "../constants/addpaymentConstants";

export const getAddpayment = (code) => async (dispatch) => {
  try {
    dispatch({ type: ADDPAYMENT_REQUEST });

    let config = {
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}api/Addpayment/getAddpayment/${code}`,
      headers: {
        "x-api-key": `${process.env.REACT_APP_API_KEY}`,
      },
    };
    const { data } = await axios(config);
    dispatch({
      type: ADDPAYMENT_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ADDPAYMENT_FAIL,
      payload:error.response.data,
    });
  }
};
