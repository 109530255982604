import {
  CONTRACT_REQUEST,
  CONTRACT_SUCCESS,
  CONTRACT_FAIL,
} from "../constants/contractConstants";

export const contractReducer = (
  state = { contract: {}, loading: true, error: "" },
  action
) => {
  switch (action.type) {
    case CONTRACT_REQUEST:
      return { loading: true, contract: {} };
    case CONTRACT_SUCCESS:
      return { loading: false, contract: action.payload.contract };
    case CONTRACT_FAIL:
      return { loading: false, contract: {}, error: action.payload };
    default:
      return state;
  }
};
