import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageNetFound from "./PageNotFound";
import { getContract } from "../actions/contractActions";

const ContractDetail = ({ history, match }) => {
  const code = match.params.id;
  const dispatch = useDispatch();
  const contractReducer = useSelector((state) => state.contractReducer);
  const { loading, error, contract } = contractReducer;

  useEffect(() => {
    dispatch(getContract(code));
  }, [dispatch, code]);

  console.log(contract);

  return loading ? (
    <div className="text-center">
      <div className="spinner-border" role="status"></div>
      <br></br>
      {"กำลังโหลดข้อมูล..."}
    </div>
  ) : error ? (
    <>
      <PageNetFound message={error} />
    </>
  ) : (
    <>
      <h2>{contract.lov_desc}</h2>
      <p>{contract.branch_address}</p>
      <div className="text-start">
        <h6>
        {contract.vatcal === "Y" ? "สาขาที่ออกใบกำกับภาษี" : "สาขาที่ออกใบเสร็จรับเงิน"} {" "}
          <span style={{ fontSize: "14px" }}>{contract.branch_name}</span>{" "}
          สาขาที่ <span style={{ fontSize: "14px" }}>{contract.branch_no}</span>
        </h6>
        <br />
        <h6>
          เลขที่ {contract.doc_no} วันที่ {contract.selling_date}
        </h6>
        <br />
        <h6>ผู้มีอำนาจออกหลักฐานการรับเงิน {contract.mng_name}</h6>
        <br />
        <h6>ผู้บริโภค {contract.customer_name}</h6>
        <br />
        <h6>ที่อยู่ {contract.cus_address}</h6>
        <br />
        <h6>เลขประจำตัวผู้เสียภาษี/บัตรประชาชน {contract.id_no}</h6>
        <br />
        <h6>
          เบอร์โทรศัพท์ {contract.tel_phone} Contract {contract.contract_no}
        </h6>
        <br />
        <h6>รายละเอียดสินค้า</h6>
        {contract.product_name.map((x, index) => {
          return (
            <li
              style={{ listStyleType: "none", paddingLeft: "10px" }}
              key={index}
              className="d-flex justify-content-between"
            >
              <span>
                {index + 1}. {x.PRODUCT_NAME} = {x.QTY}
              </span>
              <span>{x.PRICE}.-</span>
            </li>
          );
        })}
      </div>
      <br />
      <div className="d-flex justify-content-between">
        <h6>รวมจำนวนเงินค่าบริการ</h6>
        <h6>{contract.total_price}.-</h6>
      </div>
      <div className="d-flex justify-content-between">
        <h6>หักเงินมัดจำค่าบริการ</h6>
        <h6>{contract.paid === "0.00" ? "-" : contract.paid + ".-"}</h6>
      </div>
      <div className="d-flex justify-content-between">
        <h6>จำนวนเงินค่าบริการคงเหลือ</h6>
        <h6>{contract.balance === "0.00" ? "-" : contract.balance + ".-"}</h6>
      </div>
      {contract.vatcal === "Y" ? (
        <div className="text-start">
          <div className="d-flex justify-content-between">
            <h6>มูลค่าสินค้าและบริการ</h6>
            <h6>{contract.total_before_vat}.-</h6>
          </div>
          <div className="d-flex justify-content-between">
            <h6>ภาษีมูลค่าเพิ่ม 7%</h6>
            <h6>{contract.total_vat}.-</h6>
          </div>
        </div>
      ) : null}
      <div className="d-flex justify-content-between">
        <h6>จำนวนเงินค่าบริการที่ชำระ</h6>
        <h6>{contract.sum_price_sale}.-</h6>
      </div>
      <h6> ({contract.total_price_thai}) </h6>
      <br />
      <div className="d-flex justify-content-between">
        <h6>โปรแกรมนี้เริ่มวันที่ {contract.selling_date}</h6>
        <h6>สิ้นสุดวันที่ {contract.expire_date}</h6>
      </div>
      <div className="text-start">
        <h6>ชำระเงินโดย</h6>
        {contract.cash && <h6 className="me-3">เงินสด {contract.cash}.- </h6>}
        {contract.credit && <h6>บัตรเครดิต {contract.credit}.- </h6>}
      </div>
      <div className="text-center">
        <br />
        <h6>ลงชื่อ ( {contract.customer_name} ) ผู้บริโภค </h6>
        <h6>วันที่ {contract.payment_date} </h6>
        <br />
        <h6>
          ลงชื่อ (
          {contract.employee_name.map((x, index) => {
            return (
              <span key={index}>
                {" "}
                {index !== 0 && "/"} {x}{" "}
              </span>
            );
          })}
          ) ผู้รับเงิน{" "}
        </h6>
        <h6>วันที่ {contract.payment_date} </h6>
        <br />
        <h6>ลงชื่อ ( {contract.mng_name} ) ผู้มีอำนาจออกหลักฐานการรับเงิน </h6>
        <h6>วันที่ {contract.payment_date} </h6>
      </div>
      <br />
      <div className="text-start p-2">
        <h6>
          <span style={{ fontWeight: "bold" }}>หมายเหตุ</span> :
          หลักฐานการรับเงินต้องส่งมอบให้แก่ ผู้บริโภคทันที
          ที่ได้รับชำระค่าบริการ เสริมความงามจากผู้บริโภค
        </h6>
        <h6>
          1. กรณีผู้บริโภคยังไม่ได้ใช้บริการภายในระยะเวลา 7 วัน
          นับจากวันที่ได้ชำระเงินให้ผู้ประกอบธุรกิจ คืนเงินเต็มจำนวน
          กรณีที่ชำระค่าบริการผ่านบัตร เครดิต ให้ผู้ประกอบธุรกิจมีสิทธิ์
          หักค่าธรรมเนียมการใช้บัตรเครดิตได้
        </h6>
        <h6>
          2. ในกรณีที่ผู้ประกอบธุรกิจปิดกิจการ ปิดปรับปรุง
          หรือย้ายสถานที่ให้บริการ โดยผู้บริโภคไม่อาจ ใช้บริการแห่งอื่น
          หรือสาขาอื่นได้โดยสะดวก หรือไม่ สามารถให้บริการเสริม ความงามได้
          ตามเงื่อนไขที่ตกลงไว้ หรือให้บริการล่าช้าเกินสมควร
          หรือให้บริการไม่เหมาะสมอันอาจก่อให้เกิด อันตรายแก่ ผู้บริโภค
          และผู้ประกอบธุรกิจไม่สามารถ แก้ไขการให้บริการนั้นได้
          หรือผู้บริโภคมีหลักฐานรับรองจากแพทย์ว่าใช้บริการ
          เสริมความงามต่อไปอาจเป็น เหตุให้ผู้บริโภค ได้รับอันตรายต่อสุขภาพ
          หรือร่างกาย ให้ผู้ประกอบธุรกิจคืนเงินตาม สัดส่วนที่ยังไม่ได้ให้บริการ
        </h6>
      </div>
    </>
  );
};

export default ContractDetail;
